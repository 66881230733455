import React from 'react';

import { Result } from 'antd';

Notfound.title = "Page Not Found"
export default function Notfound() {

	return (
		<Result
			status="404"
			title="Sorry, the page you visited does not exist." />
	)

}